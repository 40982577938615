import * as React from "react";
import styles from "./icon.css";

type SizeOption = "xs" | "sm" | "md" | "lg" | "xl";

type IconProps = {
  color?: string;
  size?: SizeOption;
  title?: string;
  ariaHidden?: boolean;
  viewBox?: string;
};

type IconSvgWrapperProps = {
  children: React.ReactNode;
  size?: SizeOption;
  title?: string;
  ariaHidden?: boolean;
  viewBox?: string;
};

const defaultIconProps: IconProps = ({
  color: "primary-color"
} as const);

export const links = () => [{ rel: "stylesheet", href: styles }];

const SIZE_MAP = ({
  xs: "1rem",
  sm: "1.25rem",
  md: "1.5rem",
  lg: "3rem",
  xl: "4rem"
} as const);

export const IconSvgWrapper = (props: IconSvgWrapperProps) => {
  const { children, ariaHidden, size = "md", title, viewBox = "0 0 24 24" } = props;

  return (
    <svg
      className="svg-icon"
      fill="none"
      height={SIZE_MAP[size]}
      width={SIZE_MAP[size]}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={ariaHidden}>

      {title && <title>{title}</title>}
      {children}
    </svg>);

};

// This Icon is only used for Storybook to be able to autogenerate the ArgTypes based on the component
export const EmptyIcon = (_: IconProps) => <React.Fragment />;

export { defaultIconProps };
export type { IconProps };